import React, { useEffect, useState } from 'react';
import { Container, Card, Row } from 'react-bootstrap';
import style from './Report.scss';
import Layout from '@/layout/Layout';
import { Section, DateFormat } from '@/components/shared/index';
import { Btn, Ic } from '@/components/basic/index';
import { useHistory } from 'react-router';
import { getStudent, getSettlements } from '@/api/index';
import Student from '@/entity/Student';
import Settlement from '@/entity/Settlement';

const ReportTop = () => {
  const history = useHistory();
  const [students, setStudents] = useState([]);
  const [settlements, setSettlements] = useState([]);

  const toReport = (id) => history.push(`/report/${id}`);
  const toSettlement = (familyId, id) => history.push(`${familyId}/settlement/${id}`);
  const toSettlementHistory = () => history.push(`/settlement`);
  const getNextDateTime = (report) => {
    return `${report.nextDate} ${report.nextHour}:${report.nextMinute}`;
  };

  useEffect(async () => {
    const resStudent = await getStudent();
    const resSettlements = await getSettlements();
    setStudents(
      resStudent.map((r) => {
        return new Student(r);
      })
    );
    setSettlements(
      resSettlements
        .filter((row) => !row.isPaymented)
        .map((r) => {
          return new Settlement(r);
        })
    );
  }, []);
  return (
    <Layout>
      <Container fluid className={style.reportTopWrapper}>
        <Section title="生徒" first>
          <div>
            {students.map((s, i) => {
              return (
                <Card key={i} className={style.personalPanel}>
                  <Card.Title>
                    {s.familyName}
                    {s.firstName}さん
                  </Card.Title>
                  <Card.Body style={{ paddingBottom: '0.5rem' }}>
                    {s.report === undefined ? (
                      <>次回予定：先生からの報告書をお待ちください</>
                    ) : (
                      <div>
                        次回予定
                        <br />
                        <span style={{ paddingLeft: '0.5rem' }}>
                          {s.report.date === '' ? (
                            <>登録されていません</>
                          ) : (
                            <>
                              <DateFormat date={getNextDateTime(s.report)} format="YYYY年MM月DD日 HH時mm分" />～
                            </>
                          )}
                        </span>
                        <Btn
                          isCustom
                          className={style.reportBtn}
                          outline
                          prefixIcon="faFileLines"
                          click={() => toReport(s.id)}
                        >
                          報告書を確認
                        </Btn>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        </Section>
        <Section title="未精算">
          {settlements.length === 0 ? (
            <div style={{ textAlign: 'center' }}>精算依頼は0件です</div>
          ) : (
            <div>
              {settlements.map((s, i) => {
                return (
                  <Card key={i} className={style.settlementPanel}>
                    <Card.Title>
                      精算依頼日: <DateFormat date={s.requestDate} format="YYYY年MM月DD日" />
                    </Card.Title>
                    <Card.Body>
                      <Row style={{ paddingLeft: '0.7rem' }}>
                        先生：　{s.teacher.familyName} {s.teacher.firstName}
                      </Row>
                      <Row style={{ paddingLeft: '0.7rem' }}>
                        精算予定金額：　￥{s.fee ? s.fee.toLocaleString() : ''}
                      </Row>
                      <Btn
                        isCustom
                        className={style.settlementBtn}
                        outline
                        prefixIcon="faYenSign"
                        click={() => toSettlement(s.familyId, s.id)}
                      >
                        精算を確認
                      </Btn>
                    </Card.Body>
                  </Card>
                );
              })}
              <Row style={{ textAlign: 'right' }}>
                <a className={style.settlementHistory} onClick={() => toSettlementHistory()}>
                  <Ic iName="faClockRotateLeft" />
                  過去の精算へ
                </a>
              </Row>
            </div>
          )}
        </Section>
      </Container>
    </Layout>
  );
};

export default ReportTop;
