import Report from './Report';
import Teacher from './Teacher';

class Settlement {
  constructor(param = { reports: [] }) {
    this.id = param.id || null;
    this.familyId = param.familyId || null;
    this.fee = param.fee || 0;
    this.isPaymented = param.isPaymented || false;
    this.requestDate = param.requestDate || '';
    this.reports = param.reports ? param.reports.map((r) => new Report(r)) : [];
    this.teacher = param.teacher ? new Teacher(param.teacher) : new Teacher({});
  }
}

export default Settlement;
